// AuthorList.tsx

import React, { useEffect, useState } from 'react';
import styles from './AuthorList.module.css';
import { useNavigate } from 'react-router-dom';
import authorService from "../../services/authorService";
import {toast} from "react-toastify";

interface Author {
    id: string;
    name: string;
    biography?: string | null;
    photo?: string | null;
    title?: string | null;
    location?: string | null;
    books: { id: string; name: string }[];
}

const AuthorList: React.FC = () => {
    const [authors, setAuthors] = useState<Author[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAuthors = async () => {
            try {
                const data = await authorService.getAuthors();
                setAuthors(data);
            } catch (error) {
                console.error('Error fetching authors:', error);
            }
        };

        fetchAuthors();
    }, []);

    const handleAuthorClick = (author: Author) => {
        const authorId = author.id;
        if (author.books && author.books.length > 0) {
            const bookId = author.books[0].id;
            navigate(`/author/${authorId}/chat/`);
        } else {
            navigate(`/author/${authorId}/settings`);
            toast.error('This author has no books available.');
        }
    };

    return (
        <div className={styles.authorsContainer}>
            {authors.map((author) => (
                <div
                    key={author.id}
                    className={styles.authorCard}
                    onClick={() => handleAuthorClick(author)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            handleAuthorClick(author);
                        }
                    }}
                >
                    <img
                        src={author.photo || require("./../../assets/pictures/author-default-avatar.png")}
                        alt={author.name}
                        className={styles.authorImage}
                    />
                    <div className={styles.authorName}>{author.name}</div>
                    {author.title && (
                        <div className={styles.authorSubtitle}>{author.title}</div>
                    )}
                    {author.location && (
                        <div className={styles.authorLocation}>{author.location}</div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AuthorList;
