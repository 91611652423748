//client/src/context/AuthorContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import authorService from '../services/authorService';
import {AuthorProfile, Book} from '../interfaces/interfaces';

interface AuthorContextProps {
    author: AuthorProfile | null;
    books: Book[] | null;
    loading: boolean;
    error: string | null;
}

const AuthorContext = createContext<AuthorContextProps | undefined>(undefined);

export const AuthorProvider: React.FC<{ authorId: string; children: React.ReactNode }> = ({ authorId, children }) => {
    const [author, setAuthor] = useState<AuthorProfile | null>(null);
    const [books, setBooks] = useState<Book[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchAuthor = async () => {
            try {
                setLoading(true);
                const [authorProfile, authorsBooks] = await Promise.all([
                    authorService.getAuthorProfile(authorId),
                    authorService.getBooks(authorId),
                ]);
                setAuthor(authorProfile);
                setBooks(authorsBooks);
            } catch (err) {
                console.error('Error fetching author profile and books:', err);
                setError('Failed to load author data and books.');
            } finally {
                setLoading(false);
            }
        };

        fetchAuthor();
    }, [authorId]);

    return (
        <AuthorContext.Provider value={{ author, books, loading, error }}>
            {children}
        </AuthorContext.Provider>
    );
};

export const useAuthor = (): AuthorContextProps => {
    const context = useContext(AuthorContext);
    if (!context) {
        throw new Error('useAuthor must be used within an AuthorProvider');
    }
    return context;
};
