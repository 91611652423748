// src/components/authorEdit/AuthorDashboard.tsx
import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import styles from './AuthorDashboard.module.css';

const AuthorProfile = React.lazy(() => import('./AuthorProfile'));
const BookUpload = React.lazy(() => import('./BookUpload'));
const AuthorQnA = React.lazy(() => import('./AuthorQnA'));
const ResponseGuidelines = React.lazy(() => import('./ResponseGuidelines'));
const VoiceUpload = React.lazy(() => import('./VoiceUpload'));
const BookDetails = React.lazy(() => import('./BookDetails'));


const AuthorDashboard: React.FC = () => {
    return (
        <div className={styles.dashboard}>
            <nav className={styles.nav}>
                <NavLink
                    to="."
                    end
                    className={({ isActive }) => (isActive ? styles.active : '')}
                >
                    Profile
                </NavLink>
                <NavLink
                    to="upload-book"
                    className={({ isActive }) => (isActive ? styles.active : '')}
                >
                    Upload Book
                </NavLink>
                <NavLink
                    to="qna"
                    className={({ isActive }) => (isActive ? styles.active : '')}
                >
                    Q&A
                </NavLink>
                <NavLink
                    to="guidelines"
                    className={({ isActive }) => (isActive ? styles.active : '')}
                >
                    Response Guidelines
                </NavLink>
                <NavLink
                    to="upload-voice"
                    className={({ isActive }) => (isActive ? styles.active : '')}
                >
                    Upload Voice
                </NavLink>
                <NavLink
                    to="book-details"
                    className={({ isActive }) => (isActive ? styles.active : '')}
                >
                    Books and Details
                </NavLink>
            </nav>
            <div className={styles.content}>
                <React.Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route index element={<AuthorProfile />} />
                        <Route path="upload-book" element={<BookUpload />} />
                        <Route path="book-details" element={<BookDetails />} />
                        <Route path="qna" element={<AuthorQnA />} />
                        <Route path="guidelines" element={<ResponseGuidelines />} />
                        <Route path="upload-voice" element={<VoiceUpload />} />
                    </Routes>
                </React.Suspense>
            </div>
        </div>
    );
};

export default AuthorDashboard;
