import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import styles from './AuthorList.module.css';

const AuthorList: React.FC = () => {
    const [authors, setAuthors] = useState([]);

    useEffect(() => {
        const fetchAuthors = async () => {
            const authorData = await adminService.getAllAuthors();
            setAuthors(authorData);
        };

        fetchAuthors();
    }, []);

    return (
        <div className={styles.authorListContainer}>
            <h2>All Authors</h2>
            <table className={styles.authorTable}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Biography</th>
                    <th>User ID</th>
                    <th>Created At</th>
                    <th>Last Online</th>
                </tr>
                </thead>
                <tbody>
                {authors.map((author: any) => (
                    <tr key={author.id}>
                        <td>{author.id}</td>
                        <td>{author.name}</td>
                        <td>{author.biography}</td>
                        <td>{author.userId}</td>
                        <td>{author.createdAt}</td>
                        <td>{author.updatedAt}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default AuthorList;
