//client/src/utils/analytics.ts
import ReactGA from 'react-ga4';

const isProduction = process.env.REACT_APP_ENV === 'production';

export const initializeGA = (measurementId: string) => {
    if (isProduction && measurementId) {
        ReactGA.initialize(measurementId);
        ReactGA.send("pageview");
    }
};

export const trackPageView = (path: string) => {
    if (isProduction) {
        ReactGA.send({ hitType: "pageview", page: path });
    }
};

export const trackEvent = (category: string, action: string, label?: string, value?: number) => {
    if (isProduction) {
        ReactGA.event({
            category,
            action,
            label,
            value,
        });
    }
};
