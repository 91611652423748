import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Sidebar.module.css';

const Sidebar: React.FC = () => {
    return (
        <nav className={styles.sideMenu}>
            <ul>
                <li><Link to="stats">Stats</Link></li>
                <li><Link to="users">Users</Link></li>
                <li><Link to="authors">Authors</Link></li>
                <li><Link to="books">Books</Link></li>
                <li><Link to="book-admin">Add Book</Link></li>
            </ul>
        </nav>
    );
};

export default Sidebar;
