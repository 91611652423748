import axios from 'axios';
import {format, subDays} from 'date-fns';

const API_URL = process.env.REACT_APP_API_URL;

const getLast30Days = () => {
    const today = new Date();
    return Array.from({length: 30}, (_, i) => format(subDays(today, 29 - i), 'yyyy-MM-dd'));
};

const mergeDataWithLast30Days = (data: { date: string, count: number }[]) => {
    const last30Days = getLast30Days();
    const dataMap = new Map(data.map(item => [item.date, item.count]));
    return last30Days.map(date => ({
        date,
        count: dataMap.get(date) || 0,
    }));
};

const getUserStats = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/admin/users/count`, {
        headers: {Authorization: `Bearer ${token}`},
    });
    const mergedData = mergeDataWithLast30Days(response.data.usersPerDay);
    return {totalUsers: response.data.totalUsers, usersPerDay: mergedData};
};

const getAuthorStats = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/admin/authors/count`, {
        headers: {Authorization: `Bearer ${token}`},
    });
    const mergedData = mergeDataWithLast30Days(response.data.authorsPerDay);
    return {totalAuthors: response.data.totalAuthors, authorsPerDay: mergedData};
};

const getBookStats = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/admin/books/count`, {
        headers: {Authorization: `Bearer ${token}`},
    });
    const mergedData = mergeDataWithLast30Days(response.data.booksPerDay);
    return {totalBooks: response.data.totalBooks, booksPerDay: mergedData};
};

const getAllUsers = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/admin/users`, {
        headers: {Authorization: `Bearer ${token}`},
    });
    return response.data;
};

const getAllBooks = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/admin/books`, {
        headers: {Authorization: `Bearer ${token}`},
    });
    return response.data;
};

const getAllAuthors = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/admin/authors`, {
        headers: {Authorization: `Bearer ${token}`},
    });
    return response.data;
};

const indexBook = async (formData: FormData) => {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/admin/book/index`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

const adminService = {
    getUserStats,
    getAuthorStats,
    getBookStats,
    getAllUsers,
    getAllBooks,
    getAllAuthors,
    indexBook
};

export default adminService;
