// client/src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/palette.css';
import App from './App';
import {AuthProvider} from './context/AuthContext';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { initializeGA } from './utils/analytics';
import {BrowserRouter, Router} from "react-router-dom";

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
if (GA_MEASUREMENT_ID) {
    initializeGA(GA_MEASUREMENT_ID);
}

Sentry.init({
    dsn: "https://af039794d54c51d8f0110b77a595ca0c@o4507486978768896.ingest.de.sentry.io/4507487022350416",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/wisdomcom\.onrender\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <AuthProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
            <ToastContainer />
        </AuthProvider>
    </React.StrictMode>
);

reportWebVitals();
