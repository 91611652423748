// client/src/App.tsx

import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {isAuthorsechoHost} from './utils/getCurrentHost';
import AuthorsEchoRoutes from './routes/AuthorsEchoRoutes';
import FindWisdomRoutes from './routes/FindWisdomRoutes';
import {trackPageView} from "./utils/analytics";
import {StatsigProvider, useClientAsyncInit} from '@statsig/react-bindings';
import {runStatsigAutoCapture} from '@statsig/web-analytics';
import {getUserID} from './utils/userIdentifier';
import {runStatsigSessionReplay} from "@statsig/session-replay";

const REACT_APP_STATSIG_CLIENT_KEY = process.env.REACT_APP_STATSIG_CLIENT_KEY || "";
if (!REACT_APP_STATSIG_CLIENT_KEY || REACT_APP_STATSIG_CLIENT_KEY.length === 0) {
    throw new Error("STATSIG IS NOT INITIALIZED")
}

const AppContent: React.FC = () => {
    const location = useLocation();

    const {client} = useClientAsyncInit(REACT_APP_STATSIG_CLIENT_KEY, {
        userID: getUserID(),
    });

    useEffect(() => {
        trackPageView(location.pathname);
    }, [location]);

    useEffect(() => {
        runStatsigAutoCapture(client);
        runStatsigSessionReplay(client);
    }, [client]);

    return (
        <StatsigProvider client={client} loadingComponent={<div>Loading...</div>}>
            {isAuthorsechoHost() || window.location.pathname === '/authors-landing'
                ? <AuthorsEchoRoutes />
                : <FindWisdomRoutes />}
        </StatsigProvider>

    )
};

const App: React.FC = () => <AppContent/>;

export default App;
