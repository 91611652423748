// src/components/authorPage/BookSection.tsx

import React, {useEffect, useState} from 'react';
import styles from './BookSection.module.css';

import {ReactComponent as LearningsIcon} from '../../assets/icons/learnings.svg';
import {ReactComponent as InsightsIcon} from '../../assets/icons/insights.svg';
import {ReactComponent as ToolsIcon} from '../../assets/icons/tools.svg';
import divider from '../../assets/icons/divider-gradient.svg';
import Learnings from './Learnings';
import Insights from './Insights';
import Tools from './Tools';

import {CSSTransition, SwitchTransition} from 'react-transition-group';
import bookService from "../../services/bookService";
import {Book} from "../../interfaces/interfaces";
import {useParams} from "react-router-dom";
import {useAuthor} from "../../context/AuthorContext";

interface BookSectionProps {
    bookId: string | null;
    setBookId: (bookId: string | null) => void;
}

const tabs = [
    {
        key: 'learnings',
        label: 'Learnings',
        icon: LearningsIcon,
        color: '#21808D',
    },
    {
        key: 'insights',
        label: 'Insights',
        icon: InsightsIcon,
        color: '#C87156',
    },
    {
        key: 'tools',
        label: 'Practice',
        icon: ToolsIcon,
        color: '#6F499D',
    },
];

const BookSection: React.FC<BookSectionProps> = ({ bookId, setBookId }) => {

    const { author, books, loading: authorLoading, error } = useAuthor();
    const [book, setBook] = useState<Book | null>(null);
    const [learnings, setLearnings] = useState([]);
    const [insights, setInsights] = useState([]);
    const [tools, setTools] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [activeTab, setActiveTab] = useState<'learnings' | 'insights' | 'tools'>('learnings');

    useEffect(() => {
        if (!bookId && books && books.length > 0) {
            setBookId(books[0].id);
        }
    }, [books, bookId, setBookId]);

    useEffect(() => {
        const fetchData = async () => {
            if (!bookId) {
                // Reset state if no bookId
                setBook(null);
                setLearnings([]);
                setInsights([]);
                setTools([]);
                setDataLoading(false);
                return;
            }

            setDataLoading(true);
            try {
                const [bookData, learningsData, insightsData, toolsData] = await Promise.all([
                    bookService.getBook(bookId),
                    bookService.getBookLearnings(bookId),
                    bookService.getBookInsights(bookId),
                    bookService.getBookTools(bookId),
                ]);
                setBook(bookData);
                setLearnings(learningsData);
                setInsights(insightsData);
                setTools(toolsData);
            } catch (error) {
                console.error(`Error fetching book data:`, error);
            } finally {
                setDataLoading(false);
            }
        };

        fetchData();
    }, [bookId]);

    const isLoading = authorLoading || dataLoading;
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error || !author || !books) {
        return <div>Error loading books and profile.</div>;
    }


    return (
        <div className={styles.bookSection}>
            <img src={book?.coverUrl} alt="Book Cover" className={styles.bookImage}/>

            <div className={styles.tabContainer}>
                {tabs.map((tab) => {
                    const IconComponent = tab.icon;
                    const isActive = tab.key === activeTab;

                    return (
                        <button
                            key={tab.key}
                            className={`${styles.tab} ${isActive ? styles.activeTab : ''}`}
                            onClick={() => setActiveTab(tab.key as 'learnings' | 'insights' | 'tools')}
                        >
                            <IconComponent
                                className={styles.icon}
                                style={{
                                    color: isActive ? tab.color : '#1E3742',
                                    opacity: isActive ? 1 : 0.5,
                                }}
                            />
                            <span
                                className={styles.tabText}
                                style={{
                                    color: isActive ? tab.color : '#1E3742',
                                    opacity: isActive ? 1 : 0.5,
                                }}
                            >
                                {tab.label}
                            </span>
                            {isActive && (
                                <div
                                    className={styles.activeIndicator}
                                    style={{backgroundColor: tab.color}}
                                ></div>
                            )}
                        </button>
                    );
                })}
            </div>

            <img src={divider} alt="Divider" className={styles.divider}/>

            <div className={styles.contentContainer}>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={activeTab}
                        timeout={200}
                        classNames={{
                            enter: styles.fadeEnter,
                            enterActive: styles.fadeEnterActive,
                            exit: styles.fadeExit,
                            exitActive: styles.fadeExitActive,
                        }}
                        unmountOnExit
                    >
                        <div className={styles.transitionWrapper}>
                            {activeTab === 'learnings' && <Learnings learnings={learnings}/>}
                            {activeTab === 'insights' && <Insights insights={insights}/>}
                            {activeTab === 'tools' && <Tools tools={tools}/>}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </div>
    );
};

export default BookSection;
