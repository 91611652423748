import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styles from './PublicClosed.module.css';
import {useAuth} from "../../context/AuthContext";

const PublicClosed: React.FC = () => {
    const navigate = useNavigate();
    const {user, setUser} = useAuth()

    const handleLoginClick = () => {
        localStorage.removeItem('token');
        setUser(null)
        navigate('/login');
    };

    return (
        <div className={styles.publicClosed}>
            <img
                src={require('../../assets/icons/wisdom-logo.svg').default}
                alt="Wisdom Logo"
                className={styles.logo}
            />
            <h1 className={styles.title}>Wisdom is Closed for Public Access</h1>
            <p className={styles.message}>
                Thank you for your interest in Wisdom! At this time, our platform is available exclusively for authors
                and administrators.
                Please stay tuned for future updates!
            </p>
            <p className={styles.footer}>
                If you are an author,{' '}
                <span className={styles.loginLink} onClick={handleLoginClick}>
                    log in here
                </span>.
            </p>
        </div>
    );
};

export default PublicClosed;
