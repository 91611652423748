import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import styles from './BookList.module.css';

const BookList: React.FC = () => {
    const [books, setBooks] = useState([]);

    useEffect(() => {
        const fetchBooks = async () => {
            const bookData = await adminService.getAllBooks();
            setBooks(bookData);
        };

        fetchBooks();
    }, []);

    return (
        <div className={styles.bookListContainer}>
            <h2>All Books</h2>
            <table className={styles.bookTable}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Author ID</th>
                    <th>Cover URL</th>
                    <th>Voice ID</th>
                </tr>
                </thead>
                <tbody>
                {books.map((book: any) => (
                    <tr key={book.id}>
                        <td>{book.id}</td>
                        <td>{book.title}</td>
                        <td>{book.authorId}</td>
                        <td>{book.coverUrl}</td>
                        <td>{book.voiceId}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default BookList;
