// src/components/authorPage/Tools.tsx

import React from 'react';
import styles from './Tools.module.css';

// Importing icons as React components
import { ReactComponent as HoursTrackerIcon } from '../../assets/icons/hours-tracker.svg';
import { ReactComponent as CulturalLegacyIcon } from '../../assets/icons/cultural-legacy.svg';
import { ReactComponent as IntelligenceIcon } from '../../assets/icons/intelligence-quests.svg';
import { ReactComponent as InteractiveDecision } from '../../assets/icons/interactive-decision.svg';
import { ReactComponent as MsgAssesment } from '../../assets/icons/msg-assesment.svg';
import { ReactComponent as V2Mom } from '../../assets/icons/v2mom.svg';


const icons = [InteractiveDecision, MsgAssesment, V2Mom];

interface Tool {
    id: string;
    name: string;
}


const Tools: React.FC<{ tools: { id: string; name: string }[] }> = ({ tools }) => {

    return (
        <div className={styles.toolsContainer}>
            {tools.map((tool, index) => {
                // Cycle through the icons array using the modulo operator for now. Remove it later.
                const IconComponent = icons[index % icons.length];
                return (
                    <div key={tool.id} className={styles.toolItem}>
                        <button className={styles.toolButton}>
                            <IconComponent className={styles.icon} />
                            <span className={styles.toolText}>{tool.name}</span>
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export default Tools;