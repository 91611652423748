// client/src/components/authorPage/AuthorPage.tsx
import React from 'react';
import Header from './Header';
import ProfileSection from './ProfileSection';
import MainContent from './MainContent';
import styles from './AuthorPage.module.css';
import { ChatProvider } from '../../context/ChatContext';
import { AuthorProvider } from '../../context/AuthorContext';
import { useParams } from 'react-router-dom';

const AuthorPage: React.FC = () => {
    const { authorId } = useParams<{ authorId: string }>();

    if (!authorId) {
        throw new Error('Author ID is missing.');
    }

    return (
        <AuthorProvider authorId={authorId}>
            <div className={styles.authorPage}>
                <Header />
                <div className={styles.content}>
                    <ProfileSection />
                    <MainContent />
                </div>
            </div>
        </AuthorProvider>
    );
};

export default AuthorPage;
