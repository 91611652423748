import React, { useEffect, useState } from 'react';
import Modal from '../common/Modal';
import styles from './AboutMeModal.module.css';
import userFactsService from '../../services/userFactsService';
import { UserFact } from '../../interfaces/interfaces';
import TextArea from '../common/TextArea';

interface AboutMeModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const AboutMeModal: React.FC<AboutMeModalProps> = ({ isOpen, onClose }) => {
    const [facts, setFacts] = useState<UserFact[]>([]);
    const [newFact, setNewFact] = useState('');
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (isOpen) {
            fetchFacts();
        }
    }, [isOpen]);

    const fetchFacts = async () => {
        setLoading(true);
        try {
            const data = await userFactsService.getFacts();
            setFacts(data);
        } catch (error) {
            console.error('Failed to fetch facts', error);
        } finally {
            setLoading(false); // Ensure loading is set to false
        }
    };

    const handleSave = async () => {
        if (newFact.trim() === '') return;
        setSaving(true);
        try {
            const savedFact = await userFactsService.saveFact(newFact);
            const userFacts = [...facts, savedFact]
            console.log("Facts:", userFacts)
            setFacts(userFacts);
            setNewFact('');
        } catch (error) {
            console.error('Failed to save fact', error);
        } finally {
            setSaving(false);
        }
    };

    const handleDelete = async (factId: string) => {
        try {
            await userFactsService.deleteFact(factId);
            setFacts(facts.filter(fact => fact.id !== factId));
        } catch (error) {
            console.error('Failed to delete fact', error);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            if (newFact.trim() === '') {
                e.preventDefault();
                return;
            }
            e.preventDefault();
            handleSave();
        }
        // If Shift+Enter, allow default behavior (inserting newline)
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles.aboutMeModal}>
                <h2>About Me</h2>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                    {facts.length === 0 ? (
                        <p className={styles.noFactsMessage}>
                            Adding facts about yourself will improve your experience.
                            <br/>
                            For example: "I have an 8-year-old daughter."
                        </p>
                    ) : (
                            <ul className={styles.factList}>
                                {facts.map(fact => (
                                    <li key={fact.id} className={styles.factItem}>
                                        <span>{fact.fact}</span>
                                        <div className={styles.buttonContainer}>
                                            <button
                                                className={styles.deleteButton}
                                                onClick={() => handleDelete(fact.id)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )}
                <div className={styles.inputContainer}>
                    <TextArea
                        label=""
                        value={newFact}
                        onChange={(e) => setNewFact(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Add a new fact about yourself..."
                        required
                    />
                    <button
                        className={styles.saveButton}
                        onClick={handleSave}
                        disabled={saving || newFact.trim() === ''}
                    >
                        {saving ? 'Saving...' : 'Save'}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AboutMeModal;
