import React, { useState } from 'react';
import axios from 'axios';
import styles from './Stealth.module.css';

const Stealth: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/subscribe`,
                { email }
            );
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting email:', error);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <h1 className={styles.header}>
                        Shh...
                        <br />
                        <span className={styles.subheader}>We're Crafting the Future of Wisdom.</span>
                        <br />
                    </h1>
                    {isSubmitted ? (
                        <p className={styles.paragraph}>Thank you! You've been added to our special list.</p>
                    ) : (
                        <form className={styles.form} onSubmit={handleSubmit}>
                            <label htmlFor="email" className={styles.label}>Be the first to know:</label>
                            <div className={styles.inputContainer}>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    required
                                    className={styles.input}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button type="submit" className={styles.button}>Get in the Loop</button>
                            </div>
                            {error && <p className={styles.error}>{error}</p>}
                        </form>
                    )}
                </div>
                <footer className={styles.footer}>
                    <p>© 2024 Find Wisdom, Inc. All rights reserved.</p>
                    <a href="/privacy" className={styles.footerLink}>Privacy Policy</a> | <a href="/terms" className={styles.footerLink}>Terms of Service</a>
                </footer>
            </div>
        </div>
    );
}

export default Stealth;
