import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const AuthorGraph: React.FC = () => {
    const [totalAuthors, setTotalAuthors] = useState(0);
    const [authorsPerDay, setAuthorsPerDay] = useState<{ date: string, count: number }[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const { totalAuthors, authorsPerDay } = await adminService.getAuthorStats();
            setTotalAuthors(totalAuthors);
            setAuthorsPerDay(authorsPerDay);
        };

        fetchData();
    }, []);

    const maxYValue = Math.max(...authorsPerDay.map(item => item.count)) * 1.2;

    const data = {
        labels: authorsPerDay.map(item => item.date),
        datasets: [
            {
                label: 'Authors per Day',
                data: authorsPerDay.map(item => item.count),
                fill: false,
                backgroundColor: 'rgb(153, 102, 255)',
                borderColor: 'rgba(153, 102, 255, 0.2)',
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                suggestedMax: maxYValue
            }
        }
    };

    return (
        <div>
            <h2>Total Authors: {totalAuthors}</h2>
            <Line data={data} options={options} />
        </div>
    );
};

export default AuthorGraph;
