// src/components/authorPage/Insights.tsx

import React from 'react';
import styles from './Insights.module.css';

// Import SVGs as React components
import { ReactComponent as Divider } from '../../assets/icons/divider-card.svg';
import { ReactComponent as HeartIcon } from '../../assets/icons/heart.svg';
import { ReactComponent as AddToFavoritesIcon } from '../../assets/icons/addToFavorites.svg';

const Insights: React.FC<{ insights: { id: string; insight: string; likes: number }[] }> = ({ insights }) => {

    return (
        <div className={styles.insights}>
            {insights.map((insights, index) => (
                <div key={index} className={styles.card}>
                    <div className={styles.quote}>{insights.insight}</div>
                    <Divider className={styles.divider} />
                    <div className={styles.cardFooter}>
                        <div className={styles.likes}>
                            <span className={styles.likesNumber}>{insights.likes}</span>
                            <HeartIcon className={styles.heartIcon} />
                        </div>
                        <AddToFavoritesIcon className={styles.addToFavoritesIcon} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Insights;
