import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Stats from './Stats';
import UserList from './UserList';
import BookList from './BookList';
import AuthorList from './AuthorList';
import BookAdmin from './BookAdmin'; // Import BookAdmin component
import Sidebar from './Sidebar';
import styles from './AdminDashboard.module.css';

const AdminDashboard: React.FC = () => {
    return (
        <div className={styles.adminDashboard}>
            <Sidebar />
            <div className={styles.dashboardContent}>
                <Routes>
                    <Route path="/" element={<Navigate to="stats" />} />
                    <Route path="stats" element={<Stats />} />
                    <Route path="users" element={<UserList />} />
                    <Route path="books" element={<BookList />} />
                    <Route path="authors" element={<AuthorList />} />
                    <Route path="book-admin" element={<BookAdmin />} />
                </Routes>
            </div>
        </div>
    );
};

export default AdminDashboard;
