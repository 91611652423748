import React, {useState} from 'react';
import styles from './BookAdmin.module.css';
import adminService from '../../services/adminService';

const BookAdmin: React.FC = () => {
    const [title, setTitle] = useState('');
    const [authorFullName, setAuthorFullName] = useState('');
    const [authorLastName, setAuthorLastName] = useState('');
    const [bookFile, setBookFile] = useState<File | null>(null);
    const [coverFile, setCoverFile] = useState<File | null>(null);
    const [message, setMessage] = useState('');

    const handleBookFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setBookFile(e.target.files[0]);
        }
    };

    const handleCoverFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setCoverFile(e.target.files[0]);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setMessage('')
        if (!title || !authorFullName) {
            setMessage('Please fill out all fields and attach a file.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('authorFullName', authorFullName);
        if (coverFile) {
            formData.append('coverFile', coverFile);
        }
        if (bookFile) {
            formData.append('bookFile', bookFile);
        }

        try {
            const data = await adminService.indexBook(formData);
            if (!data.id) {
                setMessage('Failed to index book. Please try again.');
                return;
            }
            setMessage('Book indexed successfully.');
            setTitle('');
            setAuthorFullName('');
            setBookFile(null);
            setCoverFile(null);
        } catch (error) {
            setMessage('Failed to index book. Please try again.');
        }
    };

    const isFormValid = title && authorFullName && coverFile;

    return (
        <div className={styles.bookAdminContainer}>
            <h2>Index a New Book</h2>
            <form onSubmit={handleSubmit} className={styles.bookAdminForm}>
                <label htmlFor="title">Title:</label>
                <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />

                <label htmlFor="authorFullName">Author Full Name:</label>
                <input
                    type="text"
                    id="authorFullName"
                    value={authorFullName}
                    onChange={(e) => setAuthorFullName(e.target.value)}
                    required
                />

                <label htmlFor="coverFile">Cover File:</label>
                <input
                    type="file"
                    id="coverFile"
                    accept=".jpg,.jpeg,.png,.webp"
                    onChange={handleCoverFileChange}
                />

                <label htmlFor="bookFile">Book File (PDF or Text):</label>
                <input
                    type="file"
                    id="bookFile"
                    accept=".txt,.pdf"
                    onChange={handleBookFileChange}
                />

                <button type="submit" disabled={!isFormValid}>Index</button>
                {message && <p className={styles.message}>{message}</p>}
            </form>
        </div>
    );
};

export default BookAdmin;
