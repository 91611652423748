export enum EventCategory {
    USER_INTERACTION = "User Interaction",
    FORM_ACTION = "Form Action",
}

export enum EventAction {
    BUTTON_CLICK = "Button Click",
    FORM_SUBMISSION = "Form Submission",
}

export enum EventLabel {
    SUBMIT_BUTTON = "Submit Button",
    CANCEL_BUTTON = "Cancel Button",
}
