//client/src/services/landingAuthorService.ts

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
    throw new Error('REACT_APP_API_URL is not defined in .env file');
}


const submitForm = async (formData: any) => {
    const response = await axios.post(
        `${API_URL}/authors-landing/form`,
        { ...formData },
        {}
    );
    return response.data;
}

const landingAuthorService = {
    submitForm,
};

export default landingAuthorService;