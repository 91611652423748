// src/components/common/Header.tsx

import React from 'react';
import styles from './Header.module.css';
import wisdomLogo from '../../assets/icons/wisdom-logo.svg';

interface HeaderButton {
    label: string;
    onClick: () => void;
}

interface HeaderProps {
    buttons: HeaderButton[];
}

const Header: React.FC<HeaderProps> = ({ buttons }) => {
    return (
        <header className={styles.header}>
            <div className={styles.logoContainer}>
                <img src={wisdomLogo} alt="Wisdom Logo" className={styles.logo} />
            </div>
            <div className={styles.buttonGroup}>
                {buttons.map((button, index) => (
                    <div
                        key={index}
                        className={styles.headerButton}
                        onClick={button.onClick}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault(); // Prevent default scroll behavior
                                button.onClick();
                            }
                        }}
                    >
                        {button.label}
                    </div>
                ))}
            </div>
        </header>
    );
};

export default Header;
