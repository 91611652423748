// src/components/authorPage/Learnings.tsx

import React from 'react';
import styles from './Learnings.module.css';

// Import SVGs as React components
import { ReactComponent as Divider } from '../../assets/icons/divider-card.svg';
import { ReactComponent as HeartIcon } from '../../assets/icons/heart.svg';
import { ReactComponent as AddToFavoritesIcon } from '../../assets/icons/addToFavorites.svg';

const Learnings: React.FC<{ learnings: { id: string; learning: string; likes: number }[] }> = ({ learnings }) => {
    return (
        <div className={styles.learnings}>
            {learnings.map((learning, index) => (
                <div key={index} className={styles.card}>
                    <div className={styles.quote}>{learning.learning}</div>
                    <Divider className={styles.divider} />
                    <div className={styles.cardFooter}>
                        <div className={styles.likes}>
                            <span className={styles.likesNumber}>{learning.likes}</span>
                            <HeartIcon className={styles.heartIcon} />
                        </div>
                        <AddToFavoritesIcon className={styles.addToFavoritesIcon} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Learnings;
