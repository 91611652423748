// client/src/components/authorPage/ChatComponent.tsx

import React, {useEffect, useState} from 'react';
import {useChat} from '../../context/ChatContext';
import styles from './ChatComponent.module.css';
import {ReactComponent as VoiceIcon} from '../../assets/icons/voice.svg';
import {ReactComponent as PauseIcon} from '../../assets/icons/pause.svg';
import {ReactComponent as ThreeDotsIcon} from '../../assets/icons/three-dots.svg';
import Markdown from "markdown-to-jsx";
import authorService from "../../services/authorService";
import {AuthorProfile} from "../../interfaces/interfaces";
import {useAuthor} from "../../context/AuthorContext";

const ChatComponent: React.FC = () => {
    const {messages, handleVoiceClick, loadingAudio, playingAudio} = useChat();
    const {author, loading, error} = useAuthor();

    if (loading) {
        return <div>Loading author data...</div>;
    }

    if (error || !author) {
        return <div>Failed to load author details.</div>;
    }

    return (
        <div className={styles.chatContainer}>
            <div className={styles.messageList}>
                {
                    [...messages]
                        .reverse().map((message, index) => (
                        <div
                            key={index}
                            className={
                                message.role === 'user' ? styles.userMessage : styles.assistantMessage
                            }
                        >
                            {message.role === 'user' ? (
                                <>
                                    <div className={styles.userMessageBubble}>{message.content}</div>
                                    <img
                                        src={require('../../assets/pictures/user-avatar.png')}
                                        alt="User Avatar"
                                        className={styles.userAvatar}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className={styles.assistantMessageAndPhoto}>
                                        <img

                                            src={author.photo}
                                            alt="Assistant Avatar"
                                            className={styles.assistantAvatar}
                                        />
                                        <div className={styles.assistantMessageBubble}>
                                            <Markdown>{message.content}</Markdown>
                                        </div>
                                    </div>
                                    <div className={styles.assistantActions}>
                                        {loadingAudio === message.content ? (
                                            <div className={styles.voiceLoader}></div>
                                        ) : (
                                            <span
                                                className={styles.voiceIcon}
                                                onClick={() => handleVoiceClick(message)}
                                            >
                                            {playingAudio === message.content ? (
                                                <PauseIcon/>
                                            ) : (
                                                <VoiceIcon/>
                                            )}
                                        </span>
                                        )}
                                        <span className={styles.dotsIcon}>
                                        <ThreeDotsIcon/>
                                    </span>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ChatComponent;
