// src/routes/AuthorsEchoRoutes.tsx
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import LandingAuthors from '../components/authors-landing/LandingAuthors';

const AuthorsEchoRoutes: React.FC = () => (
    <Suspense fallback={<div>Loading...</div>}>
        <Helmet>
            <title>Authors Echo</title>
            <meta name="description" content="Bring your voice to life"/>
            <meta name="title" content="Echo"/>
            <meta property="og:title" content="AuthorsEcho.com"/>
            <meta property="og:description" content="Bring your voice to life"/>
            <meta property="og:url" content="https://authorsecho.com"/>
        </Helmet>
        <Routes>
            <Route path="*" element={<LandingAuthors/>}/>
        </Routes>
    </Suspense>
);

export default AuthorsEchoRoutes;
