/* /src/context/ChatContext.tsx */

import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import chatService, { ChatMessage } from '../services/chatService';
import { useAuth } from './AuthContext';
import removeMd from 'remove-markdown';
import { BookQuestion } from "../interfaces/interfaces";
import bookService from "../services/bookService";

interface ChatContextProps {
    messages: ChatMessage[];
    input: string;
    loading: boolean;
    setInput: (input: string) => void;
    sendMessage: () => void;
    clearChat: () => void;
    loadingAudio: string | null;
    playingAudio: string | null;
    handleVoiceClick: (message: ChatMessage) => Promise<void>;
    initialQuestions: BookQuestion[];
    handleQuestionClick: (question: string) => void;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

interface ChatProviderProps {
    authorId: string;
    bookId: string;
    children: React.ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ authorId, bookId, children }) => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingAudio, setLoadingAudio] = useState<string | null>(null);
    const [playingAudio, setPlayingAudio] = useState<string | null>(null);
    const audioCache = useRef<{ [key: string]: string }>({});
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [initialLoad, setInitialLoad] = useState(false);
    const [initialQuestions, setInitialQuestions] = useState<BookQuestion[]>([]);

    useEffect(() => {
        const fetchInitialData = async () => {
            const storedMessages = localStorage.getItem(`chatHistory-${bookId}`);
            if (!storedMessages || storedMessages === "[]") {
                try {
                    const questions = await bookService.getBookQuestions(bookId!);
                    setInitialQuestions(questions);
                } catch (error) {
                    console.error('Failed to fetch initial questions', error);
                }
            } else {
                setMessages(JSON.parse(storedMessages));
            }
            setInitialLoad(true);
        };

        if (bookId) {
            fetchInitialData();
        }
    }, [bookId]);

    useEffect(() => {
        if (initialLoad && bookId) {
            localStorage.setItem(`chatHistory-${bookId}`, JSON.stringify(messages));
        }
    }, [messages, bookId, initialLoad]);

    const addMessage = (message: ChatMessage) => {
        setMessages((prevMessages) => [message, ...prevMessages]);
    };

    const sendMessageFromInput = async () => {
        const content = input.trim();
        if (content === '') return;
        setInput('');
        await sendMessage(content);
    };

    const sendMessage = async (content: string) => {
        if (!bookId) return;

        const userMessage: ChatMessage = { role: 'user', content };
        addMessage(userMessage);
        setLoading(true);

        const loaderMessage: ChatMessage = { role: 'assistant', content: 'Loading...' };
        addMessage(loaderMessage);

        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found');

            const assistantMessage = await chatService.sendMessage(token, bookId, [userMessage, ...messages]);
            setMessages((prevMessages) => prevMessages.map(msg =>
                msg.content === 'Loading...' ? assistantMessage : msg
            ));
        } catch (error) {
            console.error('Failed to send message', error);
            setMessages((prevMessages) => prevMessages.filter(msg => msg.content !== 'Loading...'));
        } finally {
            setLoading(false);
        }
    };

    const clearChat = () => {
        if (!bookId) return;

        setMessages([]);
        localStorage.removeItem(`chatHistory-${bookId}`);
        fetchInitialQuestions();
    };

    const fetchInitialQuestions = async () => {
        if (!bookId) return;

        try {
            const questions = await bookService.getBookQuestions(bookId);
            setInitialQuestions(questions);
        } catch (error) {
            console.error('Failed to fetch initial questions', error);
        }
    };

    const handleVoiceClick = async (message: ChatMessage) => {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        if (playingAudio === message.content) {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                audioRef.current = null;
            }
            setPlayingAudio(null);
            return;
        }

        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            audioRef.current = null;
        }

        setLoadingAudio(message.content);

        try {
            let audioBase64 = audioCache.current[message.content];
            if (!audioBase64) {
                const strippedContent = removeMd(message.content, { stripListLeaders: false });
                audioBase64 = await chatService.getAudio(token, strippedContent, bookId!);
                audioCache.current[message.content] = audioBase64;
            }
            const audio = new Audio(`data:audio/mp3;base64,${audioBase64}`);
            audioRef.current = audio;
            audio.onended = () => {
                setPlayingAudio(null);
                setLoadingAudio(null);
                audioRef.current = null;
            };
            await audio.play();
            setPlayingAudio(message.content);
            setLoadingAudio(null);
        } catch (error) {
            console.error('Failed to play audio', error);
            setPlayingAudio(null);
            setLoadingAudio(null);
        }
    };

    const handleQuestionClick = (question: string) => {
        sendMessage(question);
        setInitialQuestions([]); // Remove the questions after one is selected
    };

    return (
        <ChatContext.Provider value={{
            messages,
            input,
            loading,
            setInput,
            sendMessage: sendMessageFromInput,
            clearChat,
            loadingAudio,
            playingAudio,
            handleVoiceClick,
            initialQuestions,
            handleQuestionClick,
        }}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChat = () => {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChat must be used within a ChatProvider');
    }
    return context;
};
