//client/src/services/bookService.ts

import axios from 'axios';
import {Insight, Learning, Tool} from "../interfaces/interfaces";

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
    throw new Error('REACT_APP_API_URL is not defined in .env file');
}

const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');
    return token;
};

const config = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

const getBook = async (bookId: string | undefined) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');
    try {
        const response = await axios.get(`${API_URL}/books/${bookId}`, config())
        return response.data;
    } catch (error: any) {
        console.error('Fetching books error:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.message : 'Failed to fetch books');
    }
};

const getBooksForUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');
    try {
        const response = await axios.get(`${API_URL}/books`, config())
        return response.data;
    } catch (error: any) {
        console.error('Fetching books error:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.message : 'Failed to fetch books');
    }
};

const getBookQuestions = async (bookId: string) => {
    try {
        const response = await axios.get(`${API_URL}/books/${bookId}/questions`, config());
        return response.data;
    } catch (error) {
        console.error('Error fetching book questions:', error);
        throw error;
    }
};

const getBookLearnings = async (bookId: string) => {
    const response = await axios.get(`${API_URL}/books/${bookId}/learnings`, config());
    return response.data;
};

const getBookInsights = async (bookId: string) => {
    const response = await axios.get(`${API_URL}/books/${bookId}/insights`, config());
    return response.data;
};

const getBookTools = async (bookId: string) => {
    const response = await axios.get(`${API_URL}/books/${bookId}/tools`, config());
    return response.data;
};

const updateBookLearnings = async (bookId: string, learnings: Learning[]) => {
    const response = await axios.put(`${API_URL}/books/${bookId}/learnings`, { learnings }, config());
    return response.data;
};

const updateBookInsights = async (bookId: string, insights: Insight[]) => {
    const response = await axios.put(`${API_URL}/books/${bookId}/insights`, { insights }, config());
    return response.data;
};

const updateBookTools = async (bookId: string, tools: Tool[]) => {
    const response = await axios.put(`${API_URL}/books/${bookId}/tools`, { tools }, config());
    return response.data;
};

const bookService = {
    getBooksForUser,
    getBookQuestions,
    getBook,
    getBookLearnings,
    getBookInsights,
    getBookTools,
    updateBookLearnings,
    updateBookInsights,
    updateBookTools,
};

export default bookService;
