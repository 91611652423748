import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import styles from './LoginPage.module.css';

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { setUser } = useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const user = await authService.login(email, password);
            setUser(user);
            navigate('/');
        } catch (err: any) {
            setError(err.message);
        }
    };

    document.addEventListener('focus', (e) => {
        console.log('Focused element:', e.target);
    }, true);

    return (
        <div className={styles.authPage} tabIndex={-1}>
            <div className={styles.logoContainer}>
                <img
                    src={require('../../assets/icons/wisdom-logo.svg').default}
                    alt="Wisdom Logo"
                    className={styles.logo}
                />
            </div>
            <form onSubmit={handleSubmit} className={styles.authForm}>
                <h2 className={styles.title}>Welcome Back</h2>
                <p className={styles.subtitle}>Log in to continue your journey</p>
                <label className={styles.label}>
                    Email
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className={styles.input}
                    />
                </label>
                <label className={styles.label}>
                    Password
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className={styles.input}
                    />
                </label>
                <button type="submit" className={styles.button}>Log In</button>
                {error && <p className={styles.errorMessage}>{error}</p>}
            </form>
        </div>
    );
};

export default LoginPage;
