import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import styles from './AdminLoginPage.module.css';

const AdminLoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { setUser } = useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const user = await authService.login(email, password);
            setUser(user);
            if (user.userType === 'admin') {
                navigate('/admin');
            } else {
                throw new Error('Unauthorized access');
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    return (
        <div className={styles.authPage}>
        <form onSubmit={handleSubmit} className={styles.authForm}>
        <h2>Admin Login</h2>
    <label>
    Email:
    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
    </label>
    <label>
    Password:
    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
    </label>
    <button type="submit">Login</button>
    {error && <p className={styles.errorMessage}>{error}</p>}
        </form>
        </div>
    );
    };

    export default AdminLoginPage;
