import React, { useRef, useEffect, useState } from 'react';
import BookSection from './BookSection';
import ChatComponent from './ChatComponent';
import styles from './MainContent.module.css';
import { ChatProvider, useChat } from '../../context/ChatContext';
import { ReactComponent as SendArrowIcon } from '../../assets/icons/arrow-send.svg';
import { useAuthor } from '../../context/AuthorContext';

const MainContent: React.FC = () => {
    const { author } = useAuthor();

    const [bookId, setBookId] = useState<string | null>(null);
    const authorId = author?.id;

    const scrollableContentRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    // Only provide Chat context where it's needed
    const ChatContent = () => {
        const {
            messages,
            input,
            setInput,
            sendMessage,
            loading,
        } = useChat();

        // Scroll to the bottom when messages update
        useEffect(() => {
            if (scrollableContentRef.current) {
                scrollableContentRef.current.scrollTo({
                    top: scrollableContentRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }, [messages]);

        // Auto-resize textarea
        useEffect(() => {
            if (textareaRef.current) {
                // Reset height to calculate new height
                textareaRef.current.style.height = 'auto';
                // Set new height (limited to max 4 lines)
                const maxHeight = 20 * 4 + 24; // 20px line-height * 4 lines + padding
                textareaRef.current.style.height = Math.min(textareaRef.current.scrollHeight, maxHeight) + 'px';
            }
        }, [input]);

        const handleSendMessage = () => {
            if (input.trim() !== '') {
                sendMessage();
                if (textareaRef.current) {
                    textareaRef.current.style.height = 'auto'; // Reset height after sending
                }
            }
        };

        const handleInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevent newline from being added
                handleSendMessage();
            }
        };

        return (
            <>
                <div className={styles.scrollableContent} ref={scrollableContentRef}>
                    <div className={styles.bookSection}>
                        <BookSection bookId={bookId} setBookId={setBookId} />
                    </div>
                    <div className={styles.chatComponent}>
                        <ChatComponent />
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <textarea
                        ref={textareaRef}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        placeholder="Ask anything..."
                        className={styles.inputField}
                        rows={1}
                    />
                    <div className={styles.toggleContainer}>
                        <input type="checkbox" id="confidentToggle" className={styles.toggleSwitch} />
                        <label htmlFor="confidentToggle" className={styles.toggleLabel}>
                            Confident
                        </label>
                    </div>
                    <button
                        onClick={handleSendMessage}
                        className={styles.sendButton}
                        disabled={loading}
                    >
                        <SendArrowIcon className={styles.sendArrowIcon} />
                    </button>
                </div>
            </>
        );
    };

    return (
        <div className={styles.mainContent}>
            {bookId && authorId ? (
                <ChatProvider authorId={authorId} bookId={bookId}>
                    <ChatContent />
                </ChatProvider>
            ) : (
                <div className={styles.scrollableContent} ref={scrollableContentRef}>
                    <div className={styles.bookSection}>
                        <BookSection bookId={bookId} setBookId={setBookId} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MainContent;
