// src/services/authorService.ts
import axios from 'axios';
import {VoiceSample} from "../interfaces/interfaces";

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
    throw new Error('REACT_APP_API_URL is not defined in .env file');
}

const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');
    return token;
};

const config = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

const getAuthors = async () => {
    const response = await axios.get(`${API_URL}/authors/`, config());
    return response.data;
}

const getAuthorProfile = async (authorId: string) => {
    const response = await axios.get(`${API_URL}/authors/${authorId}`, config());
    return response.data;
}

const updateProfile = async (authorId: string, data: FormData) => {
    const response = await axios.post(`${API_URL}/authors/${authorId}/update-profile`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

const uploadBook = async (authorId: string, data: FormData) => {
    const response = await axios.post(`${API_URL}/authors/${authorId}/upload-book`, data, config());
    return response.data;
};

const getQnA = async (authorId: string) => {
    const response = await axios.get(`${API_URL}/authors/${authorId}/qna`, config());
    return response.data;
};

const submitQnA = async (authorId: string, data: { qna: { question: string; answer: string }[] }) => {
    const response = await axios.post(`${API_URL}/authors/${authorId}/qna`, data, config());
    return response.data;
};
const getGuidelines = async (authorId: string, ) => {
    const response = await axios.get(`${API_URL}/authors/${authorId}/guidelines`, config());
    return response.data;
};

const saveGuidelines = async (authorId: string, data: any) => {
    const response = await axios.post(`${API_URL}/authors/${authorId}/save-guidelines`, data, config());
    return response.data;
};

const getBooks = async (authorId: string) => {
    const response = await axios.get(`${API_URL}/authors/${authorId}/books`, config());
    return response.data;
};

const getVoiceStatus = async (authorId: string) => {
    const response = await axios.get(`${API_URL}/authors/${authorId}/voice-status`, config());
    return response.data;
};

// Method to get voice samples
const getVoiceSamples = async (authorId: string): Promise<VoiceSample[]> => {
    const response = await axios.get(`${API_URL}/authors/${authorId}/voice-samples`, config());
    return response.data;
};

// Updated uploadVoice method to handle multiple files
const uploadVoice = async (authorId: string, data: FormData) => {
    const response = await axios.post(`${API_URL}/authors/${authorId}/upload-voice`, data, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

const deleteVoiceSample = async (authorId: string, sampleId: string) => {
    const response = await axios.delete(`${API_URL}/authors/${authorId}/voice-samples/${sampleId}`, config());
    return response.data;
};


const authorService = {
    getAuthorProfile,
    updateProfile,
    uploadBook,
    getQnA,
    submitQnA,
    getGuidelines,
    saveGuidelines,
    uploadVoice,
    getBooks,
    getVoiceStatus,
    getVoiceSamples,
    deleteVoiceSample,
    getAuthors,
};

export default authorService;
