import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
    throw new Error('REACT_APP_API_URL is not defined in .env file');
}

export interface ChatMessage {
    role: 'user' | 'assistant' | 'system';
    content: string;
    audio?: string;
}

const sendMessage = async (token: string, bookId: string, messages: ChatMessage[]): Promise<ChatMessage> => {
    const response = await axios.post(
        `${API_URL}/chat`,
        { messages, bookId },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data;
};

const getAudio = async (token: string, text: string, bookId: string): Promise<string> => {
    const response = await axios.post(
        `${API_URL}/tts`,
        { text, bookId },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data.audio;
};

const chatService = {
    sendMessage,
    getAudio,
};

export default chatService;
