import React from 'react';
import UserGraph from './UserGraph';
import AuthorGraph from './AuthorGraph';
import BookGraph from './BookGraph';

const Stats: React.FC = () => {
    return (
        <div>
            <h2>Statistics</h2>
            <UserGraph />
            <AuthorGraph />
            <BookGraph />
        </div>
    );
};

export default Stats;
