// src/components/authorPage/Header.tsx

import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the toast styles
import styles from './Header.module.css';
import wisdomLogo from '../../assets/icons/wisdom-logo.svg';

const Header: React.FC = () => {
    // Handle click for "Coming Soon" buttons
    const handleComingSoon = () => {
        toast.info('Coming soon!', {
            position: 'top-center', // Toast position
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: 'light',
        });
    };

    return (
        <header className={styles.header}>
            <div className={styles.logoContainer}>
                <img src={wisdomLogo} alt="Wisdom Logo" className={styles.logo} />
            </div>
            <div className={styles.buttonGroup}>
                <div className={styles.headerButton} onClick={handleComingSoon}>
                    Settings
                </div>
                <div className={styles.headerButton} onClick={handleComingSoon}>
                    Readership Dashboard
                </div>
            </div>
        </header>
    );
};

export default Header;
