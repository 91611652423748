import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const BookGraph: React.FC = () => {
    const [totalBooks, setTotalBooks] = useState(0);
    const [booksPerDay, setBooksPerDay] = useState<{ date: string, count: number }[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const { totalBooks, booksPerDay } = await adminService.getBookStats();
            setTotalBooks(totalBooks);
            setBooksPerDay(booksPerDay);
        };

        fetchData();
    }, []);

    const maxYValue = Math.max(...booksPerDay.map(item => item.count)) * 1.2;

    const data = {
        labels: booksPerDay.map(item => item.date),
        datasets: [
            {
                label: 'Books per Day',
                data: booksPerDay.map(item => item.count),
                fill: false,
                backgroundColor: 'rgb(255, 159, 64)',
                borderColor: 'rgba(255, 159, 64, 0.2)',
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                suggestedMax: maxYValue
            }
        }
    };

    return (
        <div>
            <h2>Total Books: {totalBooks}</h2>
    <Line data={data} options={options} />
    </div>
);
};

export default BookGraph;
