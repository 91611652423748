import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const UserGraph: React.FC = () => {
    const [totalUsers, setTotalUsers] = useState(0);
    const [usersPerDay, setUsersPerDay] = useState<{ date: string, count: number }[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const { totalUsers, usersPerDay } = await adminService.getUserStats();
            setTotalUsers(totalUsers);
            setUsersPerDay(usersPerDay);
        };

        fetchData();
    }, []);

    const maxYValue = Math.max(...usersPerDay.map(item => item.count)) * 1.2;

    const data = {
        labels: usersPerDay.map(item => item.date),
        datasets: [
            {
                label: 'Users per Day',
                data: usersPerDay.map(item => item.count),
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgba(75, 192, 192, 0.2)',
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                suggestedMax: maxYValue
            }
        }
    };

    return (
        <div>
            <h2>Total Users: {totalUsers}</h2>
            <Line data={data} options={options} />
        </div>
    );
};

export default UserGraph;
