import React from 'react';
import styles from './TextArea.module.css';

interface TextAreaProps {
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    required?: boolean;
    placeholder?: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void; // Add this line
}

const TextArea: React.FC<TextAreaProps> = ({
                                               label,
                                               value,
                                               onChange,
                                               required = false,
                                               placeholder,
                                               onKeyDown, // Add this line
                                           }) => {
    return (
        <label className={styles.label}>
            {label}
            <textarea
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown} // Add this line
                required={required}
                placeholder={placeholder}
                className={styles.textarea}
            />
        </label>
    );
};

export default TextArea;
