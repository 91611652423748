// MainPage.tsx

import React, { useState } from 'react';
import styles from './MainPage.module.css';
import AboutMeModal from './aboutMe/AboutMeModal';
import AuthorList from './authorList/AuthorList';
import Header from "./common/Header";

const MainPage: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAboutMeClick = () => {
        setIsModalOpen(true);
    };

    const handleSettingsClick = () => {
        // Implement your settings functionality here
        console.log('Settings clicked');
    };

    const headerButtons = [
        {
            label: 'Settings',
            onClick: handleSettingsClick,
        },
        {
            label: 'About Me',
            onClick: handleAboutMeClick,
        },
    ];

    return (
        <div className={styles.mainPage}>
            <Header buttons={headerButtons} />
            <AuthorList />
            <AboutMeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
    );
};

export default MainPage;
