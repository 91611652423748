import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import styles from './UserList.module.css';

const UserList: React.FC = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            const userData = await adminService.getAllUsers();
            setUsers(userData);
        };

        fetchUsers();
    }, []);

    return (
        <div className={styles.userListContainer}>
            <h2>All Users</h2>
            <table className={styles.userTable}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Last Name</th>
                    <th>Created At</th>
                    <th>Last Online</th>
                </tr>
                </thead>
                <tbody>
                {users.map((user: any) => (
                    <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.email}</td>
                        <td>{user.name}</td>
                        <td>{user.lastName}</td>
                        <td>{user.createdAt}</td>
                        <td>{user.updatedAt}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserList;
