// src/routes/FindWisdomRoutes.tsx
import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ProtectedRoute from '../components/routes/ProtectedRoute';
import PublicRoute from '../components/routes/PublicRoute';
import LoginPage from '../components/auth/LoginPage';
import RegisterPage from '../components/auth/RegisterPage';
import AuthorLoginPage from '../components/auth/AuthorLoginPage';
import AdminLoginPage from '../components/auth/AdminLoginPage';
import AdminDashboard from '../components/admin/AdminDashboard';
import AuthorDashboard from '../components/authorEdit/AuthorDashboard';
import MainPage from '../components/MainPage';
import StealthComponent from '../components/Stealth';
import AuthorPage from '../components/authorPage/AuthorPage';
import PublicClosed from '../components/auth/PublicClosed';

const FindWisdomRoutes: React.FC = () => {
    const { user } = useAuth();

    const HomeRedirect = () => {
        if (!user) {
            return <StealthComponent />;
        }
        if (user?.userType === 'author') {
            return <Navigate to={`/author/${user.authorId}/chat`} />;
        }
        if (user?.userType === 'regular') {
            return <PublicClosed />;
        }
        return <MainPage />;
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/login" element={<PublicRoute element={<LoginPage />} redirectTo="/" />} />
                <Route path="/register" element={<PublicRoute element={<RegisterPage />} redirectTo="/" />} />
                <Route path="/author/login"
                       element={<PublicRoute element={<AuthorLoginPage />} redirectTo="/author" />} />
                <Route path="/admin/login"
                       element={<PublicRoute element={<AdminLoginPage />} redirectTo="/admin" />} />
                <Route path="/admin/*"
                       element={user?.userType === 'admin' ? <AdminDashboard /> : <Navigate to="/" />} />
                <Route path="/author/:authorId/chat/" element={<ProtectedRoute element={<AuthorPage />} />} />
                <Route
                    path="/author/:authorId/settings/*"
                    element={
                        user?.userType === 'author' || user?.userType === 'admin' ? (
                            <AuthorDashboard />
                        ) : (
                            <Navigate to="/" />
                        )
                    }
                />
                <Route path="/" element={<HomeRedirect />} />
                <Route path="*" element={user ? <Navigate to="/" /> : <StealthComponent />} />
            </Routes>
        </Suspense>
    );
};

export default FindWisdomRoutes;
